// Click Body Toggles
function clickBodyToggle(e, elem) {
    e.preventDefault();
    document.body.classList.toggle(elem + '-active');
}
function addBodyToggle(elem) {
    var elemA = document.querySelectorAll('a[href="#' + elem + '"]'),
        elemALength = elemA.length;

    for (var i = 0; i < elemALength; i += 1) {
        elemA[i].addEventListener('click', function(e) {
            if (elem === 'menu') {
                $('html, body').animate({
                    scrollTop: 0
                }, 250);
            }
            clickBodyToggle(e, elem);
        });
    }
}
addBodyToggle('menu');
// End Click Body Toggles

// Show Contact Footer
let cf = document.querySelector('.footer-right');
let cfc = document.querySelector('.close-footer-contact');
let cstat = 'closed';

cf.addEventListener('click', () => {
    if (cstat === 'closed') {
        cstat = 'open';
        cf.classList.remove('footer-contact-default');
        document.body.classList.add('show-footer-contact');
        /* document.body.classList.add('body-overflow');
        setTimeout( () => {
            document.body.classList.remove('body-overflow');
        }, 300); */
    }
});
cfc.addEventListener('click', () => {
    cf.classList.add('footer-contact-default');
    // document.body.classList.add('body-overflow');
    setTimeout( () => {
        cstat = 'closed';
        document.body.classList.remove('show-footer-contact');
        // document.body.classList.remove('body-overflow');
    }, 300);
});
// End Show Contact Footer

// Projects Slider
$('#projects-slider').carousel({
    pause: false
});

let slideActiveNow = false;

let cil = $('#projects-slider .carousel-item').length;
$('#projects-slider').on('slide.bs.carousel', function (s) {
    if (slideActiveNow === false) {
        slideActiveNow = true;
        setTimeout(function(){
            slideActiveNow = false;
        }, 1000);
    } else {
        return s.preventDefault();
    }

    $('.slide-before-before').removeClass('slide-before-before');
    $('.slide-before').removeClass('slide-before');
    $('.slide-after').removeClass('slide-after');
    $('.slide-after-after').removeClass('slide-after-after');
    if (s.direction === 'left') {
        s.from += 1;
        s.fromB = s.from - 1;
        s.to += 2;
        s.toA = s.to + 1;
        if (s.fromB === 0) {
            s.fromB = cil;
        }
        if (s.to > cil) {
            s.to = 1;
        }
        if (s.toA > cil) {
            if (s.toA === cil + 2) {
                s.toA = 2;
            } else {
                s.toA = 1;
            }
        }
    } else {
        s.from -= 1;
        s.fromB = s.from - 1;
        s.to += 2;
        s.toA = s.to + 1;
        if (s.from === 0) {
            s.from = cil;
        }
        if (s.from === -1) {
            s.from = cil -1;
        }
        if (s.to > cil) {
            s.to = 1;
        }
        if (s.toA > cil) {
            if (s.toA === cil + 2) {
                s.toA = 2;
            } else {
                s.toA = 1;
            }
        }
        if (s.fromB === -2) {
            s.fromB = cil - 2;
        }
        if (s.fromB === -1) {
            s.fromB = cil - 1;
        }
        if (s.fromB === 0) {
            s.fromB = cil;
        }
        console.log(s.fromB);
    }
    $('#projects-slider .carousel-item:nth-child(' + s.fromB + ')').addClass('slide-before-before');
    $('#projects-slider .carousel-item:nth-child(' + s.from + ')').addClass('slide-before');
    $('#projects-slider .carousel-item:nth-child(' + s.to + ')').addClass('slide-after');
    $('#projects-slider .carousel-item:nth-child(' + s.toA + ')').addClass('slide-after-after');
});
// End Projects Slider

// Scroll Menu
/* window.addEventListener('scroll', () => {
   if (window.scrollY > 250) {
       document.body.classList.add('scrolled-down');
   } else {
       document.body.classList.remove('scrolled-down');
   }
}); */
// End Scroll Menu

// Hide Nav
$(window).click(function() {
    document.body.classList.remove('menu-active');
});

$('#nav, a.menu').click(function(event){
    event.stopPropagation();
});
